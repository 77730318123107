import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ loggedIn, handleLogout }) => {
    // Verificar si el usuario está autenticado
    if (!loggedIn) {
      return null; // Retorna null si el usuario no está autenticado
    }
  
    return (
      <div className="navbar">
        <Link to="/">Home</Link>
        {loggedIn && <Link to="/profile">Profile</Link>}
        {loggedIn && (
          <Link to="/logout" onClick={handleLogout}>
            Logout
          </Link>
        )}
      </div>
    );
  };
export default Navbar;