import { Link } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import {useIdioma} from '../IdiomaContext';
import {traducciones} from "./Traducciones";

const Datos = (props) => {
  const location = useLocation();
  const { idioma } = useIdioma();
  const texto = traducciones[idioma] || traducciones.es;

  const { formData } = location.state || {};

  return (
    <section className="bg-gray-50 dark:bg-gray-900 pb-4">
      <div className="flex flex-col items-center justify-center px-6 py-6 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-7 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-5 space-y-4 md:space-y-4 sm:p-8">
            {formData ? (
              <>
                <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  {texto.datos}
                </h1>
                <h2 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  {texto.atentamente}
                </h2>
                <h2 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  ZEIN, S.L.
                </h2>
              </>
            ) : (
              <>
                <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  {texto.noDatosTitulo}
                </h1>
                <p>Regresa al <b><Link to="/">formulario</Link></b> para completar la información.</p>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Datos;