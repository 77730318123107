const traducciones = {
    es: {
        generarIncidencia: 'Generar incidencia',
        idioma: 'Cambiar idioma',
        titulo: 'Formulario de incidencia:',
        enviando: 'Enviando...',
        notas: 'Por favor, rellene todos los campos de este formulario y a continuación haga clic en:',
        condiciones: "Para cualquier avería que la arrendataria comunique a la arrendadora a través de este canal, ésta se reserva el derecho de enviar al servicio técnico que considere y en caso de que la avería, según el servicio técnico, sea responsabilidad de la arrendataria, ésta acepta hacerse cargo de la totalidad de los costes asociados.",
        nombre: "Nombre",
        direccion: "Dirección",
        telefono: "Teléfono (escribir sin prefijo +34)",
        correo: "Correo",
        averia: "Avería",
        video: '(Opcional) Adjuntar vídeo (máx. 10s)',
        imagen: 'Adjuntar fotos',
        disponibilidad: "A continuación, se le piden horarios de disponibilidad. Por favor, informe una horquilla horaria de 3h seguidas como mínimo en cada recuadro para que los operarios puedan trabajar con un margen de tiempo adecuado. Probablemente sean necesarias al menos dos visitas durante las próximas semanas.",
        disponibilidad1: "Disponibilidad para la próxima semana",
        disponibilidad2: "Disponibilidad para dentro de dos semanas",
        disponibilidad3: "Disponibilidad para dentro de tres semanas",
        disponibilidad4: "Disponibilidad para dentro de cuatro semanas",
        noDatosTitulo: "No hay datos disponibles",
        noDatosMensaje: "Regresa al formulario para completar la información.",
        camposobligatorios: "Los siguientes campos son obligatorios:",
        numeroincorrecto: "El número de teléfono es incorrecto",
        condicionesincorrecto: "Para poder enviar la incidencia, debe aceptar la casilla al inicio del formulario",
        imgobligatorio: "Por favor, adjunte una foto",
        imgincorrecto: "El formato de la imagen no es válido. Por favor, utiliza un formato de imagen válido.",
        videoincorrecto: "El formato del video no es válido. Por favor, utiliza un formato de video válido.",
        datos: "Formulario de incidencia enviado correctamente. Próximamente nuestro servicio técnico se pondrá en contacto con usted (posiblemente a través de un número privado).",
        atentamente: "Atentamente,",
        label: "Escribir aquí"
    },
    ca: {
        generarIncidencia: 'Generar incidència',
        idioma: 'Canviar idioma',
        titulo: "Formulari d'incidencia:",
        enviando: 'Enviant...',
        notas: "Si us plau, ompliu tots els camps d'aquest formulari i feu clic a:",
        condiciones: "Per a qualsevol avaria que l'arrendatària comuniqui a l'arrendadora a través d’aquest canal, aquesta es reserva el dret d'enviar al servei tècnic que consideri i en cas que l'avaria, segons el servei tècnic, sigui responsabilitat de l'arrendatària, aquesta accepta fer-se càrrec de la totalitat dels costos associats.",
        nombre: "Nom:",
        direccion: "Direcció",
        telefono: "Telèfon (escriure sense prefix +34)",
        correo: "Mail de contacte",
        averia: "Descripció de l'incidència",
        video: '(Opcional) Adjuntar video (màx. 10s)',
        imagen: 'Adjuntar fotos',
        disponibilidad: "A continuació, se us demanen horaris de disponibilitat. Si us plau, informeu una forquilla horària de 3h seguides com a mínim en cada requadre perquè els operaris puguin treballar amb un marge de temps adequat. Probablement siguin necessàries almenys dues visites durant les properes setmanes.",
        disponibilidad1: "Disponibilitat setmana propera",
        disponibilidad2: "Disponibilitat per a dintre de dues setmanes",
        disponibilidad3: "Disponibilitat per a dintre de tres setmanes",
        disponibilidad4: "Disponibilitat per a dintre de quatre setmanes",
        noDatosTitulo: "No hi ha dades disponibles",
        noDatosMensaje: "Torna al formulari per completar la informació.",
        camposobligatorios: "Els camps següents són obligatoris:",
        numeroincorrecto: "El número de telèfon és incorrecte",
        condicionesincorrecto: "Per poder enviar la incidència, heu d'acceptar la casella a l'inici del formulari",
        imgobligatorio: "Si us plau, adjunti una foto",
        imgincorrecto: "El format de la imatge no és vàlid. Utilitzeu un format d'imatge vàlid.",
        videoincorrecto: "El format del vídeo no és vàlid. Si us plau, utilitzeu un format de vídeo vàlid.",
        datos: "Formulari d’incidència enviat correctament. Properament el nostre servei tècnic es posarà en contacte amb vostè.",
        atentamente: "Atentament,",
        label: "Escriure aquí"
    },
    en: {
        generarIncidencia: 'Generate incident',
        idioma: 'Change language',
        titulo: 'Incident form:',
        enviando: 'Sending',
        notas: 'Please fill out all the fields of this form and then click:',
        condiciones: 'For any breakdown that the lessee communicates to the lessor through this channel, the latter reserves the right to send to the technical service that it considers and in the event that the breakdown, according to the technical service, is the responsibility of the lessee, she agrees to take charge of all associated costs.',
        nombre: 'Name',
        direccion: 'Address',
        telefono: 'Telephone number (write without +34)',
        correo: 'Contact mail',
        averia: 'Description of the breakdown',
        video: '(Optional) Attach video (max 10s)',
        imagen: 'Attach photos',
        disponibilidad: "Next, you are asked for availability times. Please provide a time range of at least 3 hours in a row in each box so that operators can work with an adequate time margin. At least two visits will probably be necessary during the next few weeks.",
        disponibilidad1: "Availability for next week",
        disponibilidad2: "Availability for two weeks from now",
        disponibilidad3: "Availability for three weeks from now",
        disponibilidad4: "Availability for four weeks from now",
        noDatosTitulo: "No data available",
        noDatosMensaje: "Return to the form to complete the information.",
        camposobligatorios: "The following fields are required:",
        numeroincorrecto: "The phone number is incorrect",
        condicionesincorrecto: "In order to send the incident, you must accept the box at the beginning of the form",
        imgobligatorio: "Please attach a photo",
        imgincorrecto: "The image format is invalid. Please use a valid image format.",
        videoincorrecto: "The video format is invalid. Please use a valid video format.",
        datos: "Incident form sent correctly. Our technical service will contact you soon.",
        atentamente: "Yours faithfully,",
        label: "Write here"
    },
  };
  
  export { traducciones };