import React, { createContext, useContext, useState } from 'react';
import Idioma from './components/Idioma';

const IdiomaContext = createContext();

const IdiomaProvider = ({ children }) => {
  const [idioma, setIdioma] = useState(localStorage.getItem('idioma') || null);

  const seleccionarIdioma = (nuevoIdioma) => {
    localStorage.setItem('idioma', nuevoIdioma);
    setIdioma(nuevoIdioma);
  };

  const contextValue = { idioma, seleccionarIdioma };

  return (
    <IdiomaContext.Provider value={contextValue}>
      {idioma ? children : <Idioma />}
    </IdiomaContext.Provider>
  );
};

const useIdioma = () => {
  const context = useContext(IdiomaContext);
  if (!context) {
    throw new Error('useIdioma debe ser utilizado dentro de un IdiomaProvider');
  }
  return context;
};

export { IdiomaProvider, useIdioma };
