import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdioma } from '../IdiomaContext';

const SeleccionIdioma = () => {
  const { seleccionarIdioma } = useIdioma();
  const navigate = useNavigate();

  const handleSeleccionarIdioma = (idioma) => {
    seleccionarIdioma(idioma);
    navigate('/');
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 pb-4">
      <div className="flex flex-col items-center justify-center px-6 py-6 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-7 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-5 space-y-4 md:space-y-4 sm:p-8">
            <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Seleccionar idioma:
            </h1>
            <div className='text-center'>
              <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={() => handleSeleccionarIdioma('ca')}>Català</button>
              <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={() => handleSeleccionarIdioma('es')}>Castellano</button>
              <button className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={() => handleSeleccionarIdioma('en')}>English</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeleccionIdioma;