import React, {useState} from "react";
import {useNavigate,Link} from 'react-router-dom';
import {useIdioma} from '../IdiomaContext';
import {traducciones} from "./Traducciones";
import axios from "axios";
import IconoCorreo from './iconos/IconoCorreo';
import IconoDireccion from './iconos/IconoDireccion';
import IconoTelefono from './iconos/IconoTelefono';
import IconoImagen from './iconos/IconoImagen';
import IconoVideo from './iconos/IconoVideo';
import IconoUsuario from './iconos/IconoUsuario';

const Home = () => {
    const navigate = useNavigate();
    const {idioma} = useIdioma();
    const texto = traducciones[idioma] || traducciones.es;

    const [formData, setFormData] = useState({
        condiciones: '',
        nombre: '',
        direccion: '',
        telefono: '',
        correo: '',
        averia: '',
        imagen: '',
        video: '',
        disponibilidad1: '',
        disponibilidad2: '',
        disponibilidad3: '',
        disponibilidad4: '',
    });

    const handleChange = ({ target }) => {
        const { name, value, type, checked  } = target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const esFormatoImagenValido = (nombreArchivo) => {
        const extensionesValidas = ['.jpg', '.jpeg', '.png'];
        const extension = nombreArchivo.toLowerCase().substring(nombreArchivo.lastIndexOf('.'));
        return extensionesValidas.includes(extension);
    };

    const esFormatoVideoValido = (nombreArchivo) => {
        const extensionesValidas = ['.mp4', '.avi', '.mov', '.mkv'];
        const extension = nombreArchivo.toLowerCase().substring(nombreArchivo.lastIndexOf('.'));
        return extensionesValidas.includes(extension);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const camposObligatorios = ['nombre', 'direccion', 'telefono', 'correo', 'averia', 'disponibilidad1','disponibilidad2','disponibilidad3','disponibilidad4'];
        const camposVacios = camposObligatorios.filter((campo) => !formData[campo]?.trim());

        camposVacios.forEach((campo) => {
            const inputElement = document.getElementById(campo);
            if (inputElement) {
                inputElement.classList.add('campo-incompleto');
            }
        });

        if (camposVacios.length > 0) {
            setError(texto.camposobligatorios);
            setSuccess(null);
            return;
        }

        if (!formData.condiciones) {
            setError(texto.condicionesincorrecto);
            setSuccess(null);
            return;
        }

        if (formData.telefono.length !== 9) {
            setError(texto.numeroincorrecto);
            setSuccess(null);
            return;
        }

        if (!formData.imagen) {
            setError(texto.imgobligatorio);
            setSuccess(null);
            return;
        }

        try {
            setLoading(true);
            const formPayload = { ...formData };

            // Obtén todos los archivos cargados en el campo de imagen
            const imagenFiles = e.target.imagen.files;

            // Inicializa un array para almacenar las imágenes en formato base64
            const imagenesBase64 = [];

            // Itera sobre cada archivo de imagen
            for (let i = 0; i < imagenFiles.length; i++) {
                const imagenFile = imagenFiles[i];

                // Verifica si el formato de la imagen es válido
                if (!esFormatoImagenValido(imagenFile.name)) {
                    setError(texto.imgincorrecto);
                    setSuccess(null);
                    return;
                }

                // Convierte el archivo de imagen a base64
                const imagenBase64 = await convertirArchivoABase64(imagenFile);

                // Agrega la imagen en formato base64 al array
                imagenesBase64.push(imagenBase64);
            }

            // Asigna el array de imágenes base64 al campo de imagen en el payload del formulario
            formPayload.imagen = imagenesBase64;

            const videoFile = e.target.video.files[0];
            if (videoFile)
            {
                if (!esFormatoVideoValido(videoFile.name)) {
                    setError(texto.videoincorrecto);
                    setSuccess(null);
                    return;
                } else
                {
                    const videoBase64 = await convertirArchivoABase64(videoFile);
                    formPayload.video = videoBase64;
                }
            }
            
            await axios.post('https://zeinsl.cat/incidencias', formPayload);
            setSuccess("¡Incidencia generada correctamente!");
            setError(null);
            setTimeout(() => {
                navigate('/datos', { state: { formData } });
            }, 1500);
        } catch (error) {
            console.error("Error en el registro: ", error);
            setError("Hubo un error en al generar la incidencia. Inténtalo de nuevo.");
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    // Función para convertir un archivo a Base64
    async function convertirArchivoABase64(file) {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
        });
    }

    const formFields = [
        { name: 'condiciones', type: 'checkbox', label: texto.condiciones, placeholder: texto.label, required: true, maxLength: null, multiple: false, icon: null },
        { name: 'nombre', type: 'text', label: texto.nombre, placeholder: texto.label, required: true, maxLength: 50, multiple: false, icon: <IconoUsuario className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'direccion', type: 'text', label: texto.direccion, placeholder: texto.label, required: true, maxLength: 100, multiple: false, icon: <IconoDireccion className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'telefono', type: 'number', label: texto.telefono, placeholder: texto.label, required: true, maxLength: 9, multiple: false, icon: <IconoTelefono className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'correo', type: 'email', label: texto.correo, placeholder: texto.label, required: true, maxLength: 75, multiple: false, icon: <IconoCorreo className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'averia', type: 'textarea', label: texto.averia, placeholder: texto.label, required: true, maxLength: 800, multiple: false, icon: null },
        { name: 'imagen', type: 'file', label: texto.imagen, placeholder: texto.label, required: false, multiple: true, icon: <IconoImagen className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'video', type: 'file', label: texto.video, placeholder: texto.label, required: false, multiple: false, icon: <IconoVideo className="w-4 h-4 text-gray-500 dark:text-gray-400" /> },
        { name: 'disponibilidad1', type: 'textarea', label: texto.disponibilidad1, placeholder: texto.label, required: true, maxLength: 200, multiple: false, icon: null },
        { name: 'disponibilidad2', type: 'textarea', label: texto.disponibilidad2, placeholder: texto.label, required: true, maxLength: 200, multiple: false, icon: null },
        { name: 'disponibilidad3', type: 'textarea', label: texto.disponibilidad3, placeholder: texto.label, required: true, maxLength: 200, multiple: false, icon: null },
        { name: 'disponibilidad4', type: 'textarea', label: texto.disponibilidad4, placeholder: texto.label, required: true, maxLength: 200, multiple: false, icon: null }
    ];
    
    return (
        <section className="bg-gray-50 dark:bg-gray-900 pb-4">
            <div className="flex flex-col items-center justify-center px-6 py-6 mx-auto lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-7 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-5 space-y-4 md:space-y-4 sm:p-8">
                        <Link to="/idioma">{texto.idioma}</Link>
                        <h1 className="text-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            {texto.titulo}
                        </h1>
                        <p className="text-center text-gray-900 text-lg dark:text-white">{texto.notas}<br /> <i className="text-center">"{texto.generarIncidencia}"</i></p>
                        <form className="space-y-3 md:space-y-3"  onSubmit={handleSubmit}>
                            {formFields.map(({ name, type, label, placeholder, required, maxLength, multiple, icon }) => (
                                <div key={name}>
                                    {name === 'disponibilidad1' && <p className="text-justify mt-4 mb-4">{texto.disponibilidad}</p>}
                                    
                                    {type === 'textarea' ? (
                                        <>
                                        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                                        <textarea
                                            id={name}
                                            name={name}
                                            value={formData[name]}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            rows="2"
                                            maxLength={maxLength}
                                            required={required}
                                            placeholder={placeholder}
                                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        />
                                        </>
                                    ) : type === 'checkbox' ? (
                                        <div className="flex items-center">
                                            <input
                                                type={type}
                                                id={name}
                                                name={name}
                                                checked={formData[name]}
                                                onChange={handleChange}
                                                required={required}
                                                className="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-600"
                                            />
                                            <label htmlFor={name} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                {label}
                                            </label>
                                        </div>
                                    ) : (
                                        <>
                                        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                {icon}
                                            </div>
                                                <input
                                                    type={type}
                                                    id={name}
                                                    name={name}
                                                    value={formData[name]}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    maxLength={maxLength}
                                                    multiple={multiple}
                                                    required={required}
                                                    placeholder={placeholder}
                                                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                />
                                        </div>
                                        </>
                                    )}
                                </div>
                            ))}
                            <hr />
                            <button
                                type="submit"
                                className={`w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 ${
                                    loading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                disabled={loading}
                                >
                                {loading ? texto.enviando : texto.generarIncidencia}
                            </button>
                            {error && (
                                <div key="error" className="p-4 mb-4 text-center text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <span className="font-medium">¡Error!</span> {error}
                                </div>
                            )}
                            {success && (
                                <div key="success" className="p-4 mb-4 text-center text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                                    <span className="font-medium">¡Éxito!</span> {success}
                                </div>
                            )}
                        </form>
                        <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
                            &copy; 2024 Zein S.L. Versión 2.2.1
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;